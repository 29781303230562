/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
		//inicio tab-module
		//tabModule.init();
		// inicio bootstrap lightbox
		$(document).on('click', '[data-toggle="lightbox"]', function(event){
			event.preventDefault();
			$(this).ekkoLightbox({
				alwaysShowClose: true,
				showArrows: true,
			});
		});
		
		//inicio sliphover
		$('#container').sliphover({
        	backgroundColorAttr: 'data-background'
        });
		$('#container2').sliphover({
        	backgroundColorAttr: 'data-background'
        });
		//fim sliphover
        
    // slick - fotos onde atendo //
		$('.autoplay').slick({
			dots: false,
			infinite: true,
			speed: 600,
			slidesToShow: 4,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 3000,
      responsive: [ {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: 
        {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: 
        {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
		});

		// inicio justified gallery
		if ($(window).width() > 576) {
			$('#galeria').justifiedGallery(
			{
				lastRow : 'justify', 
				rowHeight : 120, 
				maxRowsCount : 0,
				randomize : false,
				rel : 'FS',
				margins : 1
			}).on('jg.complete', function () 
			{
				$(this).find('a').colorbox(
				{
					maxWidth : '80%',
					maxHeight : '80%',
					opacity : 0.8,
					transition : 'elastic',
					current : ''
				});
			});
		}
		else {
			$('#galeria').justifiedGallery(
			{
				lastRow : 'justify',
				rowHeight : 120,
				maxRowHeight : 0,
				maxRowsCount : 0,
				randomize : false,
				rel : 'FS',
				margins : 1
			}).on('jg.complete', function () 
			{
				$(this).find('a').colorbox(
				{
					maxWidth : '95%',
					maxHeight : '95%',
					opacity : 0.8,
					transition : 'elastic',
					current : ''
				});
			});
		}
        
    if ($(window).width() > 576) {
			$('#galeria2').justifiedGallery(
			{
				lastRow : 'justify', 
				rowHeight : 120, 
				maxRowsCount : 0,
				randomize : false,
				rel : 'FS',
				margins : 1
			}).on('jg.complete', function () 
			{
				$(this).find('a').colorbox(
				{
					maxWidth : '80%',
					maxHeight : '80%',
					opacity : 0.8,
					transition : 'elastic',
					current : ''
				});
			});
		}
		else {
			$('#galeria2').justifiedGallery(
			{
				lastRow : 'justify',
				rowHeight : 120,
				maxRowHeight : 0,
				maxRowsCount : 0,
				randomize : false,
				rel : 'FS',
				margins : 1
			}).on('jg.complete', function () 
			{
				$(this).find('a').colorbox(
				{
					maxWidth : '95%',
					maxHeight : '95%',
					opacity : 0.8,
					transition : 'elastic',
					current : ''
				});
			});
		}
		// justified gallery
		
		//inicio btn-toggle
		$(".btn-toggle").click(function(e){
			e.preventDefault();
			el = $(this).data('element');
			el2 = $(this).data('element2');
			$(el).fadeIn(1000);
			$(el2).fadeOut(300);
			$('html, body').animate({scrollTop : $("#mt-embalagem-img").offset().top},800);
		});
		//fim btn-toggle
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
